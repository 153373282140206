import React from 'react'
import { connect } from 'react-redux'
import { Grid, Form, Segment, TextArea, Header, Button, Divider } from 'semantic-ui-react'
import { Trans, withTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import firebase from 'firebase/app'
import 'firebase/functions'

import fecha from 'fecha'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import YearMonthDayPicker from './YearMonthDayPicker'
import Modal from '../Modals/Modal'
import { modalActions } from '../Modals/state'
import { dateFormat } from '../../utils/constants'
import { validateEmail, nl2br } from '../../utils/helpers'
import { getLocaleMonths, getLocaleWeekLong, getLocaleWeekShort } from '../../utils/calendar'

const summaryModalId = 'gratis-modal'

class GratisFrom extends React.Component {
  state = {
    dateFrom: '',
    dateTo: '',
    adults: { 0: { name: '', surname: '' } },
    children: [],
    note: '',
    budget: '',
    flight: '',
    phone: '',
    email: '',
    recaptcha: false,
    errors: {}
  }

  componentDidMount() {
    const { t } = this.props
    const budgetOptions = t('gratis.budget.options', { returnObjects: true })
    const flightOptions = t('gratis.flight.options', { returnObjects: true })

    // Default Budget and Flight to state
    this.setState({ budget: budgetOptions[0].value, flight: flightOptions[0].value })
  }

  handleSummary = () => {
    const { toggleModal } = this.props

    if (!this.isFormValid) {
      toggleModal('notification', { type: 'error', text: 'Please fill out all required fields.' })
    } else {
      toggleModal(summaryModalId)
    }
  }

  handleFormSubmit = () => {
    const { toggleModal, t, destinations } = this.props
    const { dateFrom, dateTo, adults, children, note, phone, flight, budget, email, recaptcha } = this.state

    if (recaptcha) {
      const child = children
        .map(({ dob, childName }, idx) => `Child ${idx + 1}, name: ${childName}, born: ${dob}`)
        .join(', ')

      const adultList = Object.keys(adults)
        .map(
          adultId =>
            `Adult ${parseInt(adultId) + 1}, name: ${adults[adultId].name}, surname: ${adults[adultId].surname}`
        )
        .join(', ')

      const msg = {
        to: process.env.SENDGRID_MAIL_TO,
        cc: email,
        from: email,
        subject: t(`gratis.mail.subject`),
        html: `
        <div style="font-family: sans-serif;">
          <h2>${t('gratis.period')}</h2>
          <p>${dateFrom} - ${dateTo}</p>
          <h2>${t('gratis.adults')}</h2>
          <p>${Object.keys(adults).length}</p>
          <h2>${t('gratis.adults')}</h2>
          <p>${adultList}</p>
          <h2>${t('gratis.children')}</h2>
          <p>${child}</p>
          <h2>${t('gratis.flight.title')}</h2>
          <p>${flight}</p>
          <h2>${t('gratis.message')}</h2>
          <p>${note}</p>
          <h2>${t('gratis.phone')}</h2>
          <p>${phone}</p>
          <h2>${t('gratis.budget.title')}</h2>
          <p>${budget}</p>
          <h2>${t('gratis.email')}</h2>
          <p>${email}</p>
          <h2>${t('wishlist.header.title')}</h2>
          <p>${nl2br(destinations)}</p>
        </div>
      `
      }

      const sendMail = firebase.functions().httpsCallable('sendMail')

      sendMail(msg).then(({ data }) => {
        if (data.status === 'success') {
          toggleModal('notification', { type: 'success', text: t('gratis.mail.success') })
        } else {
          toggleModal('notification', { type: 'error', text: t('gratis.mail.error') })
        }
      })
    }
  }

  handleInputChange = (e, { name, value }) => {
    return this.setState(prev => ({
      [name]: value,
      errors: { ...prev.errors, [name]: !value }
    }))
  }

  handleAdultChange = (e, { name, value, adult }) => {
    let changedAdults = { ...this.state.adults }
    changedAdults[adult][name] = value

    this.setState({ ...this.state, adults: changedAdults })
  }

  handleInputAdultCheck = (adult, input) => {
    let newErrorAdults = { ...this.state.adults }

    if (!this.state.adults[adult][input]) {
      newErrorAdults[adult]['error_' + input] = true
    } else {
      newErrorAdults[adult]['error_' + input] = false
    }

    this.setState({ ...this.state, adults: newErrorAdults })
  }

  handleInputChildCheck = (child, input) => {
    let newErrorChildren = [...this.state.children]

    if (!this.state.children[child][input]) {
      newErrorChildren[child]['error_' + input] = true
    } else {
      newErrorChildren[child]['error_' + input] = false
    }

    this.setState({ ...this.state, children: newErrorChildren })
  }

  handleInputCheck = e => {
    const name = e.target.name
    if (!this.state[name]) {
      this.setState(prev => ({ errors: { ...prev.errors, [name]: true } }))
    } else {
      this.setState(prev => ({ errors: { ...prev.errors, [name]: false } }))
    }

    return e
  }

  handleEmailChange = (e, { value }) => {
    return this.setState(prev => ({
      email: value,
      errors: { ...prev.errors, email: !validateEmail(value) }
    }))
  }

  handleDateChange = (date, modifiers, dateInput) => {
    const name = dateInput.input.name
    const value = fecha.format(date, dateFormat)

    this.setState({ [name]: value })
  }

  handleDateCheck = name => {
    if (!this.state[name]) {
      this.setState(prev => ({ errors: { ...prev.errors, [name]: true } }))
    } else {
      this.setState(prev => ({ errors: { ...prev.errors, [name]: false } }))
    }
  }

  incrementAdult = () => {
    const adultsLength = Object.keys(this.state.adults).length
    const newId = adultsLength === 0 ? 0 : adultsLength
    let changedAdults = { ...this.state.adults }
    changedAdults[newId] = { name: '', surname: '' }

    this.setState({ ...this.state, adults: changedAdults })
  }

  decrementAdult = () => {
    if (Object.keys(this.state.adults).length > 1) {
      let newAdults = { ...this.state.adults }
      const lastKey = Object.keys(newAdults)[Object.keys(newAdults).length - 1]
      delete newAdults[lastKey]
      this.setState({ ...this.state, adults: newAdults })
    }
  }

  changeChildAge = (date, id) => {
    const value = fecha.format(date, dateFormat)
    const children = this.state.children.map((child, childId) =>
      childId === id
        ? {
          dob: value,
          error_childName: this.state.children[id].error_childName,
          childName: this.state.children[id].childName
        }
        : child
    )

    this.setState({ children })
  }

  handleChangeChildName = (e, { id }) => {
    const value = e.target.value
    const children = this.state.children.map((child, childId) =>
      childId === id
        ? {
          dob: this.state.children[id].dob,
          error_childName: this.state.children[id].error_childName,
          childName: value
        }
        : child
    )

    this.setState({ children })
  }

  incrementChild = () => {
    this.setState({ children: [...this.state.children, { dob: '' }] })
  }

  decrementChild = () => {
    if (this.state.children.length > 0) {
      this.setState({ children: this.state.children.slice(0, -1) })
    }
  }

  isFormValid = () => {
    const { dateFrom, dateTo, adults, children, phone, email } = this.state
    let adultsValid = true
    let childrenValid = true

    Object.keys(children).forEach(child => {
      if (!children[child].dob || !children[child].childName) {
        childrenValid = false
      }
    })

    Object.keys(adults).forEach(adult => {
      if (!adults[adult].name || !adults[adult].surname) {
        adultsValid = false
      }
    })

    return dateFrom && dateTo && phone && adultsValid && childrenValid && validateEmail(email)
  }

  render() {
    const { dateFrom, dateTo, adults, children, note, phone, flight, budget, email, recaptcha, errors } = this.state
    const { t } = this.props
    const budgetOptions = t('gratis.budget.options', { returnObjects: true })
    const flightOptions = t('gratis.flight.options', { returnObjects: true })

    return (
      <div>
        <Segment color='red' inverted attached='top'>
          <Trans i18nKey={'gratis.offer'}>
            <Header as='h3' inverted>
              Gratis Offerte anfragen
              <br />
              <Header.Subheader>für die gesamte Wunschliste</Header.Subheader>
            </Header>
          </Trans>
        </Segment>

        <Segment attached>
          <Form onSubmit={this.submit}>
            <Form.Field required>
              <label>{t('gratis.period')}</label>
              <Form.Group widths='equal'>
                <DayPickerInput
                  placeholder={t('gratis.from')}
                  inputProps={{
                    name: 'dateFrom',
                    autoComplete: 'off',
                    className: `${errors.dateFrom ? 'error' : ''}`,
                    readOnly: true
                  }}
                  format={dateFormat}
                  formatDate={(date, format) => fecha.format(date, format)}
                  onDayChange={this.handleDateChange}
                  dayPickerProps={{
                    modifiers: {
                      disabled: { before: new Date() }
                    },
                    selectedDays: fecha.parse(dateFrom, dateFormat),
                    months: getLocaleMonths(),
                    weekdaysLong: getLocaleWeekLong(),
                    weekdaysShort: getLocaleWeekShort()
                  }}
                  onDayPickerHide={() => this.handleDateCheck('dateFrom')}
                />
                <DayPickerInput
                  placeholder={t('gratis.to')}
                  inputProps={{
                    name: 'dateTo',
                    autoComplete: 'off',
                    className: `${errors.dateTo ? 'error' : ''}`,
                    readOnly: true
                  }}
                  format={dateFormat}
                  formatDate={(date, format) => fecha.format(date, format)}
                  onDayChange={this.handleDateChange}
                  dayPickerProps={{
                    modifiers: {
                      disabled: { before: dateFrom ? fecha.parse(dateFrom, dateFormat) : new Date() }
                    },
                    fromMonth: new Date(),
                    initialMonth: dateTo || dateFrom ? fecha.parse(dateTo || dateFrom, dateFormat) : new Date(),
                    selectedDays: fecha.parse(dateTo, dateFormat),
                    months: getLocaleMonths(),
                    weekdaysLong: getLocaleWeekLong(),
                    weekdaysShort: getLocaleWeekShort()
                  }}
                  onDayPickerHide={() => this.handleDateCheck('dateTo')}
                />
              </Form.Group>
            </Form.Field>

            <Form.Field>
              <label>{t('gratis.adults')}</label>
              <Form.Group widths='equal'>
                <Form.Input
                  placeholder={t('gratis.adults')}
                  name='adults'
                  type='number'
                  value={Object.keys(this.state.adults).length}
                  fluid
                  className='gratis-form-input-left gratis-form-small-input'
                />
                <Button icon='plus' basic onClick={this.incrementAdult} />
                <Button icon='minus' basic onClick={this.decrementAdult} />
              </Form.Group>
            </Form.Field>

            {Object.keys(this.state.adults).map((adult, id) => (
              <Form.Field key={id} required>
                <label>
                  {id + 1}. {t('gratis.adult')}
                </label>
                <Form.Input
                  placeholder={t('gratis.name')}
                  name='name'
                  adult={id}
                  onChange={this.handleAdultChange}
                  onBlur={() => this.handleInputAdultCheck(id, 'name')}
                  fluid
                  error={this.state.adults[id].error_name}
                />
                <Form.Input
                  placeholder={t('gratis.surname')}
                  name='surname'
                  adult={id}
                  onChange={this.handleAdultChange}
                  onBlur={() => this.handleInputAdultCheck(id, 'surname')}
                  fluid
                  error={this.state.adults[id].error_surname}
                />
              </Form.Field>
            ))}

            <Form.Field>
              <label>{t('gratis.children')}</label>
              <Form.Group widths='equal'>
                <Form.Input
                  placeholder={t('gratis.children')}
                  type='number'
                  name='children'
                  value={children.length}
                  fluid
                />
                <Button icon='plus' basic onClick={this.incrementChild} />
                <Button icon='minus' basic onClick={this.decrementChild} />
              </Form.Group>
            </Form.Field>

            {children.map((child, id) => (
              <Form.Field key={id} required>
                <label>
                  {id + 1}. {t('gratis.child')}
                </label>
                <Form.Input
                  placeholder={t('gratis.childName')}
                  name='childName'
                  onChange={this.handleChangeChildName}
                  onBlur={() => this.handleInputChildCheck(id, 'childName')}
                  error={this.state.children[id].error_childName}
                  id={id}
                  child={id}
                  fluid
                />
                <Form.Group>
                  <YearMonthDayPicker t={t} dob={child.dob} id={id} onDateChage={this.changeChildAge} />
                </Form.Group>
              </Form.Field>
            ))}

            <Form.Field>
              <label>{t('gratis.flight.title')}</label>
              <Form.Select
                name='flight'
                options={flightOptions}
                selectOnBlur={false}
                onChange={this.handleInputChange}
                defaultValue={flightOptions[0].value}
                fluid
              />
            </Form.Field>

            <Form.Field>
              <label>{t('gratis.message')}</label>
              <TextArea
                placeholder={t('gratis.message')}
                name='note'
                onChange={this.handleInputChange}
                rows='6'
                className='gratis-form-textarea'
              />
            </Form.Field>

            <Form.Field required>
              <label>{t('gratis.phone')}</label>
              <Form.Input
                placeholder={t('gratis.phone')}
                name='phone'
                onChange={this.handleInputChange}
                onBlur={this.handleInputCheck}
                fluid
                maxLength={20}
                error={errors.phone}
              />
            </Form.Field>

            <Form.Field>
              <label>{t('gratis.budget.title')}</label>
              <Form.Select
                name='budget'
                options={budgetOptions}
                onChange={this.handleInputChange}
                defaultValue={budgetOptions[0].value}
                fluid
              />
            </Form.Field>

            <Form.Field required>
              <label>{t('gratis.email')}</label>
              <Form.Input
                placeholder={t('gratis.email')}
                name='email'
                onChange={this.handleEmailChange}
                onBlur={this.handleInputCheck}
                fluid
                error={errors.email}
              />
            </Form.Field>

            <Button disabled={!this.isFormValid()} floated='right' color='red' onClick={this.handleSummary}>
              {t('gratis.send')}
            </Button>
            <Divider clearing fitted hidden />

          </Form>
        </Segment>

        <Modal
          id={summaryModalId}
          header={t('gratis.modal.header')}
          onConfirm={this.handleFormSubmit}
          okLabel={t('gratis.modal.okLabel')}
          cancelLabel={t('gratis.modal.cancelLabel')}
          size='tiny'
          disabled={!recaptcha}
        >
          <Grid centered>
            <Grid.Column width={8}>
              <Header sub>{t('gratis.period')}</Header>
              {dateFrom} - {dateTo}
            </Grid.Column>

            <Grid.Column width={8}>
              <Header sub>{t('gratis.flight.title')}</Header>
              <span>{flight || '-'}</span>
            </Grid.Column>

            <Grid.Column width={8}>
              <Header sub>{t('gratis.adults')}</Header>
              {Object.keys(adults).length}
            </Grid.Column>

            <Grid.Column width={8}>
              <Header sub>{t('gratis.children')}</Header>
              {children.length ? (
                children.map(({ dob, childName }, idx) => (
                  <div key={idx}>
                    {idx + 1} - {childName}, {dob}
                  </div>
                ))
              ) : (
                  <span>-</span>
                )}
            </Grid.Column>

            <Grid.Column width={8}>
              <Header sub>{t('gratis.adults')}</Header>
              <span>
                {Object.keys(adults).map((adultId, key) => {
                  return (
                    <div key={key}>
                      {parseInt(adultId) + 1} - {adults[adultId].name} {adults[adultId].surname}
                    </div>
                  )
                })}
              </span>
            </Grid.Column>

            <Grid.Column width={8}>
              <Header sub>{t('gratis.message')}</Header>
              <span>{note || '-'}</span>
            </Grid.Column>

            <Grid.Column width={8}>
              <Header sub>{t('gratis.phone')}</Header>
              <span>{phone || '-'}</span>
            </Grid.Column>

            <Grid.Column width={8}>
              <Header sub>{t('gratis.budget.title')}</Header>
              <span>{budget || '-'}</span>
            </Grid.Column>

            <Grid.Column width={16}>
              <Header sub>{t('gratis.email')}</Header>
              <span>{email}</span>
            </Grid.Column>

            <Grid.Row>
              <Grid.Column width={10}>
                <ReCAPTCHA
                  sitekey={process.env.RECAPTCHA_CLIENT_KEY}
                  onChange={() => this.setState({ recaptcha: true })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal>
      </div>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal
}

export default withTranslation()(connect(null, mapDispatchToProps)(GratisFrom))
