import React, { Component } from 'react'
import { Form } from 'semantic-ui-react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import fecha from 'fecha'

import { getLocaleMonths, getLocaleWeekLong, getLocaleWeekShort } from '../../utils/calendar'

const dateFormat = 'DD.MM.YYYY'
const currentYear = new Date().getFullYear()
const fromMonth = new Date(currentYear - 18, 0)
const toMonth = new Date(currentYear, 11)

function YearMonthForm ({ date, localeUtils, onYearChange, onMonthChange }) {
  const months = localeUtils.getMonths()

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const monthsOptions = months.map((month, i) => ({
    key: getLocaleMonths()[i],
    value: i,
    text: getLocaleMonths()[i]
  }))

  const yearsOptions = years.map(year => ({
    key: year,
    value: year,
    text: year
  }))

  return (
    <div className='DayPicker-Caption'>
      <Form.Group widths='equal'>
        <Form.Select fluid options={monthsOptions} onChange={onMonthChange} value={date.getMonth()} />
        <Form.Select fluid options={yearsOptions} onChange={onYearChange} value={date.getFullYear()} />
      </Form.Group>
    </div>
  )
}

class YearMonthDayPicker extends Component {
  state = {
    year: new Date().getFullYear() - 18,
    month: 0
  }

  handleYearChange = (e, { value }) => {
    this.setState({ year: value })
  }

  handleMonthChange = (e, { value }) => {
    this.setState({ month: value })
  }

  changeChildAge = () => {}

  render () {
    const { dob, id, onDateChage } = this.props
    const { year, month } = this.state
    const currentMonth = new Date(year, month)

    const { t } = this.props

    return (
      <DayPickerInput
        value={dob}
        placeholder={`${t('gratis.dateOfBirth')}`}
        format={dateFormat}
        formatDate={(date, format) => fecha.format(date, format)}
        onDayChange={date => onDateChage(date, id)}
        inputProps={{ autoComplete: 'off', readOnly: true }}
        dayPickerProps={{
          month: currentMonth,
          fromMonth: fromMonth,
          toMonth: toMonth,
          canChangeMonth: false,
          months: getLocaleMonths(),
          weekdaysLong: getLocaleWeekLong(),
          weekdaysShort: getLocaleWeekShort(),
          captionElement: ({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onYearChange={this.handleYearChange}
              onMonthChange={this.handleMonthChange}
            />
          )
        }}
      />
    )
  }
}

export default YearMonthDayPicker
