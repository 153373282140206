import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import Modal from './Modal'

const ConfirmModal = ({ id, onConfirm, text, t }) => {
  return (
    <Modal id={id} onConfirm={onConfirm} size='mini'>
      <Segment textAlign='center' basic vertical>
        <Icon className='ta-close' size='massive' color='red' />
      </Segment>
      <p dangerouslySetInnerHTML={{__html: text || t('confirm-modal.sure')}} />
    </Modal>
  )
}

export default withTranslation()(ConfirmModal)
